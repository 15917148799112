<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <batch titleName="产品批次档案"></batch>
  </div>
</template>
<script>
  import batch from '@/views/common/batch.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  export default {
    name: 'batchLists',
    components: {
      batch,
      breadcrumb
    },
    data() {
      return {
        breadStaticData:['生产档案管理', '创建产品批次档案'],
        linkData:[],
        thirdStaticData:[],
        contHeight: ''
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
</style>